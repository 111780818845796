import Decimal from '@st/decimal'
import type { Freebet } from '../types'

export function useFreebet(freebet: Ref<Freebet>) {
  const status = computed(() => {
    if (!freebet.value.betId) return 'new'
    if (freebet.value.betId && !freebet.value.payoff) return 'process'
    if (freebet.value.betId && freebet.value.rollingStartedAt) return 'rolling'
    return ''
  })

  const { format: formatMultiplier } = useNumberFormatter()
  const { format: formatCrypto } = useCryptoFormatter({
    currency: freebet.value.currencyCode,
  })
  const { parseDate } = useDate()
  const { t, locale } = useI18n()
  const now = parseDate()
  const listFormatter = new Intl.ListFormat(locale.value, {
    style: 'long',
    type: 'conjunction',
  })

  const wager = computed(() =>
    formatMultiplier(
      freebet.value.freebetRollingSettings.rollingMultiplier ?? 0,
    ),
  )

  const currentRewardAmount = computed(() =>
    formatCrypto(freebet.value.payoff ?? 0),
  )

  const maxRollingAmount = computed(() =>
    new Decimal(freebet.value.payoff ?? '0')
      .times(freebet.value.freebetRollingSettings.rollingMultiplier)
      .toString(),
  )

  const maxRollingAmountCurrency = computed(() =>
    formatCrypto(maxRollingAmount.value),
  )

  const minRollingAmountCurrency = computed(() => formatCrypto(0))

  const minMaxRollingText = computed(
    () =>
      `${formatCrypto(freebet.value.currentRollingAmount)}/${formatCrypto(
        maxRollingAmount.value,
      )}`,
  )

  const currentProgressAmount = computed(() =>
    formatCrypto(freebet.value.currentRollingAmount),
  )

  const progress = computed(() => {
    if (status.value !== 'rolling') return ''

    return new Decimal(freebet.value.currentRollingAmount)
      .div(maxRollingAmount.value)
      .mul(100)
      .toFixed(2)
      .toString()
  })

  const amountLeft = computed(() => {
    if (!freebet.value.payoff) return 0
    return new Decimal(freebet.value.payoff)
      .mul(freebet.value.freebetRollingSettings.rollingMultiplier)
      .minus(freebet.value.currentRollingAmount)
      .toString()
  })

  const amountLeftCrypto = computed(() => formatCrypto(amountLeft.value))

  const currentExpiredDate = computed(() => {
    if (status.value === 'new') {
      return freebet.value.expiredAt
    }
    if (status.value === 'rolling') {
      return parseDate(freebet.value.rollingStartedAt)
        .add(freebet.value.freebetRollingSettings.rollingPeriodInHours, 'hours')
        .toISOString()
    }
    return now.toISOString()
  })

  const timerTitle = computed(() => {
    if (status.value === 'new') return t('bonuses.activationTimerFinish')
    if (status.value === 'rolling')
      return t('bonuses.activationConditionFinish')
    return ''
  })

  const betTypeMap = {
    ordinary: t('bonuses.ordinary'),
    express: t('bonuses.express'),
  }

  const allowedBetTypes = computed(() =>
    freebet.value.freebetSettings.allowedBetTypes.map(
      (item) => betTypeMap[item],
    ),
  )

  const minMaxRateMessage = computed(() => {
    const minRate = freebet.value.freebetSettings.minBetRate
    const maxRate = freebet.value.freebetSettings.maxBetRate

    if (minRate && !maxRate) {
      return t('bonuses.minRate', { minRate })
    }
    if (!minRate && maxRate) {
      return t('bonuses.maxRate', { maxRate })
    }

    if (minRate && maxRate) {
      return t('bonuses.minMaxRate', { minRate, maxRate })
    }
    return ''
  })

  const list = computed(() => [
    {
      label: t('bonuses.bettingOdds'),
      value: minMaxRateMessage.value,
    },
    {
      label: t('bonuses.bettingType'),
      value: listFormatter.format(allowedBetTypes.value),
    },
    {
      label: t('bonuses.bettingCondition'),
      value: t('bonuses.wager', {
        multiplier: freebet.value.freebetRollingSettings.rollingMultiplier,
      }),
      disabled: freebet.value.freebetRollingSettings.rollingMultiplier === '0',
    },
  ])

  const filteredList = computed(() =>
    list.value.filter((item) => !item.disabled),
  )

  const subtitle = computed(() => {
    switch (status.value) {
      case 'new':
        return t('bonuses.betDone')
      case 'process':
        return t('bonuses.betWon')
      default:
        return t('bonuses.shouldMakeBet')
    }
  })

  return {
    currentRewardAmount,
    minMaxRollingText,
    wager,
    status,
    progress,
    maxRollingAmount,
    amountLeft,
    currentExpiredDate,
    timerTitle,
    filteredList,
    subtitle,
    currentProgressAmount,
    amountLeftCrypto,
    maxRollingAmountCurrency,
    minRollingAmountCurrency,
  }
}
