import { useCurrencies } from '@st/payments/stores/currencies'
import { useCountdownV2 } from '@st/i18n/composables/useCountDown'
import { isNullish } from '@st/utils'
import cashbackDesktop from './img/default-cashback.png'
import rakebackDesktop from './img/default-rakeback.png'
import retraceDesktop from './img/default-retrace.png'
import reloadDesktop from './img/default-reload-bonus.png'
import cashbackMobile from './img/mobile-cashback.png'
import rakebackMobile from './img/mobile-rakeback.png'
import retraceMobile from './img/mobile-retrace.png'
import reloadMobile from './img/mobile-reload-bonus.png'
import claimAnimation from '../../assets/claim-animation.json'
import { useBonusesStore } from '../../stores/useBonusesStore'
import type { RegularBonus } from '../../stores/useRegularBonusesStore'

export function useRegularBonus(bonus: Ref<RegularBonus>) {
  const router = useRouter()
  const { currencies } = storeToRefs(useCurrencies())
  const { t } = useI18n()
  const { format } = useCryptoFormatter()
  const { updateBonusCounters } = useBonusesStore()

  const cashbackUrl = usePublicFileUrl('publicFileCashbackTerms')
  const rakeBackUrl = usePublicFileUrl('publicFileRakeBackTerms')
  const reloadBonusUrl = usePublicFileUrl('publicFileReloadBonusTerms')
  const ratRaceUrl = usePublicFileUrl('publicFileRatRaceTerms')

  const { countdown: countdownToAccrual } = useCountdownV2(
    computed(() => bonus.value.accrualAt ?? ''),
  )

  const { countdown: countdownToExpiration } = useCountdownV2(
    computed(() => bonus.value.expiredAt ?? ''),
  )

  const { execute, status, error } = useStFetch('/bonus/claim', {
    method: 'post',
    body: computed(() => ({
      bonusId: bonus.value.bonusId ?? 0,
    })),
    immediate: false,
    watch: false,
  })

  async function handleBonusClaim() {
    try {
      await execute()
      updateBonusCounters()
    } catch (err) {
      console.error(err)
    }
  }
  async function handleBonusButton() {
    if (bonus.value.status === 'readyToClaim') {
      await handleBonusClaim()
    } else {
      router.push('/casino')
    }
  }

  const content = computed<{
    title: string
    tooltipText: string
    img: string
    mobileImg: string
    link: string
  }>(() => {
    switch (bonus.value.type) {
      // @ts-expect-error rat race bonuses are disabled for now
      case 'ratRace':
        return {
          title: t('bonuses.tournament'),
          tooltipText: t('bonuses.tournamentTip'),
          img: retraceDesktop,
          mobileImg: retraceMobile,
          link: ratRaceUrl.value,
        }
      case 'cashback':
        return {
          title: t('bonuses.weeklyCashback'),
          tooltipText: t('bonuses.weeklyCashbackTip'),
          img: cashbackDesktop,
          mobileImg: cashbackMobile,
          link: cashbackUrl.value,
        }
      case 'rakeBack':
        return {
          title: t('bonuses.rakeback'),
          tooltipText: t('bonuses.rakebackTip'),
          img: rakebackDesktop,
          mobileImg: rakebackMobile,
          link: rakeBackUrl.value,
        }
      case 'reload':
        return {
          title: t('bonuses.reload'),
          tooltipText: t('bonuses.reloadTip'),
          img: reloadDesktop,
          mobileImg: reloadMobile,
          link: reloadBonusUrl.value,
        }
      default:
        return {
          title: '',
          tooltipText: '',
          img: '',
          mobileImg: '',
          link: '',
        }
    }
  })

  const currencyIcon = computed(
    () => currencies.value[bonus.value.currencyId || 1]?.icon,
  )

  const isHiddenAmount = computed(() => isNullish(bonus.value.amount))

  return {
    content,
    handleBonusButton,
    status,
    countdownToAccrual,
    countdownToExpiration,
    currencyIcon,
    format,
    error,
    claimAnimation,
    isHiddenAmount,
  }
}
