import { useUserStore } from '@st/user/stores/useUserStore'

export type RegularBonusType = 'cashback' | 'rakeBack' | 'reload'

export interface ReadyToClaimBonus {
  status: 'readyToClaim'

  type: RegularBonusType
  currencyId: number
  amount: string
  bonusId: number
  expiredAt: string

  accrualAt?: never
}

export interface WaitingForAccrualBonus {
  status: 'waitingForAccrual'

  type: RegularBonusType
  accrualAt: string
  amount?: string
  currencyId?: number

  expiredAt?: never
  bonusId?: never
}

export interface DefaultBonus {
  status: 'default'

  type: RegularBonusType
  amount?: string
  currencyId?: number

  expiredAt?: never
  accrualAt?: never
  bonusId?: never
}

export type RegularBonus =
  | ReadyToClaimBonus
  | WaitingForAccrualBonus
  | DefaultBonus

/**
 * Логика работы с регулярными бонусами довольно поехавшая
 * Используется огромная пачка роутов чтобы все правильно отрисовать
 *
 * Этот стор нужен чтобы все это говнище инкапсулировать
 * На выходе получается единообразный массив бонусов типа RegularBonus
 *
 * Жизненный цикл бонуса может включать 3 этапа
 * status: default - юзер не участвует в бонусной программе
 * status: waitingForAccrual - юзер участвует в программе и ждет начисления награды
 * status: readyToClaim - награда начислена и ее можно забрать
 */
export const useRegularBonusesStore = defineStore('regularBonuses', () => {
  const {
    data: bonuses,
    execute: fetchBonuses,
    refresh,
  } = useStFetch('/bonus/find', {
    method: 'post',
    body: {
      params: {
        expired: false,
        claimed: false,
        bonusProgramType: ['cashback', 'rakeBack', 'reload'],
      },
      pagination: {
        perPage: 50,
        orderBy: [
          {
            fieldName: 'id',
            sortOrder: 'ASC',
          },
        ],
      },
    },
    default: () => [],
    transform: (response) => response.data,
    immediate: false,
  })

  const { data: cashbackPrograms } = useStFetch('/cashback/list', {
    method: 'post',
    default: () => [],
  })

  const { data: reloadPrograms } = useStFetch('/reload-program/list', {
    method: 'post',
    default: () => [],
  })

  const cashbacks = computed<RegularBonus[]>(() => {
    if (!cashbackPrograms.value.length) {
      return [
        {
          type: 'cashback',
          status: 'default',
        },
      ]
    }

    return cashbackPrograms.value.map((program) => {
      const bonus = bonuses.value.find(
        (item) =>
          item.bonusProgramId === program.bonusProgramId &&
          item.bonusProgramType === 'cashback',
      )

      if (bonus) {
        return {
          type: 'cashback',
          status: 'readyToClaim',
          amount: bonus.amount,
          currencyId: bonus.currencyId,
          bonusId: bonus.id,
          expiredAt: bonus.expiredAt,
        }
      }
      return {
        type: 'cashback',
        status: 'waitingForAccrual',
        accrualAt: program.accrualAt,
      }
    })
  })

  const reloads = computed<RegularBonus[]>(() => {
    const reloadsWithPrograms = reloadPrograms.value.map((program) => {
      const bonus = bonuses.value.find(
        (item) =>
          item.bonusProgramId === program.bonusProgramId &&
          item.bonusProgramType === 'reload',
      )

      if (bonus) {
        return {
          type: 'reload' as const,
          status: 'readyToClaim' as const,
          amount: bonus.amount,
          currencyId: bonus.currencyId,
          bonusId: bonus.id,
          expiredAt: bonus.expiredAt,
        }
      }
      return {
        type: 'reload' as const,
        status: 'waitingForAccrual' as const,
        accrualAt: program.accrualAt,
      }
    })
    const reloadsWithoutPrograms = bonuses.value
      .filter(
        (item) =>
          item.bonusProgramType === 'reload' &&
          !reloadsWithPrograms.find(({ bonusId }) => item.id === bonusId),
      )
      .map((bonus) => ({
        type: 'reload' as const,
        status: 'readyToClaim' as const,
        amount: bonus.amount,
        currencyId: bonus.currencyId,
        bonusId: bonus.id,
        expiredAt: bonus.expiredAt,
      }))

    return [...reloadsWithPrograms, ...reloadsWithoutPrograms]
  })

  const rakebacks = computed<RegularBonus[]>(() => {
    const rakebackBonuses = bonuses.value.filter(
      (bonus) => bonus.bonusProgramType === 'rakeBack',
    )

    const result: RegularBonus[] = rakebackBonuses.map((bonus) => ({
      type: 'rakeBack',
      status: 'readyToClaim',
      bonusId: bonus.id,
      amount: bonus.amount,
      currencyId: bonus.currencyId,
      expiredAt: bonus.expiredAt,
    }))

    if (!result.length) {
      result.push({
        type: 'rakeBack',
        status: 'default',
        amount: '0',
        currencyId: 1,
      })
    }

    return result
  })

  const completedBonuses = computed<RegularBonus[]>(() => [
    ...cashbacks.value,
    ...reloads.value,
    ...rakebacks.value,
  ])

  const { isAuthenticated } = storeToRefs(useUserStore())

  function refreshBonuses() {
    if (isAuthenticated.value) {
      refresh()
    }
  }
  watch(
    () => isAuthenticated.value,
    (isAuth) => {
      if (isAuth) {
        fetchBonuses()
      }
    },
    { immediate: true },
  )

  return {
    completedBonuses,
    refreshBonuses,
  }
})
