import Decimal from '@st/decimal'
import { useCurrencies } from '@st/payments/stores/currencies'
import type { Freespin } from '../types'
import { useFreespinsStore } from '../stores/useFreespinsStore'

export function useFreespin(freespin: Ref<Freespin>) {
  const { t } = useI18n()

  const { rollingGamesNames } = storeToRefs(useFreespinsStore())
  const stFetch = useRawStFetch()

  const gameName = ref('')

  async function fetchGameTitle() {
    if (!freespin.value.freespinGameId) return

    const gameNameFromStore =
      rollingGamesNames.value[freespin.value.freespinGameId]

    if (gameNameFromStore) {
      gameName.value = gameNameFromStore
      return
    }

    try {
      const response = await stFetch('/casino/game/find', {
        method: 'post',
        body: {
          params: {
            gameId: [freespin.value.freespinGameId],
          },
          pagination: {
            orderBy: [
              {
                sortOrder: 'ASC',
                fieldName: 'weight',
              },
            ],
          },
        },
      })
      gameName.value = response.data?.[0]?.name ?? ''
      rollingGamesNames.value[freespin.value.freespinGameId] = gameName.value
    } catch (error) {
      console.error(error)
    }
  }
  watchEffect(() => {
    if (freespin.value.freespinGameId) {
      fetchGameTitle()
    }
  })

  const { getCurrencyById } = useCurrencies()
  const { format: formatFiat } = useCurrencyFormatter({
    currency: getCurrencyById(freespin.value.freespinCurrencyId)?.code ?? 'usd',
  })

  const { format: formatMultiplier } = useNumberFormatter()

  const currentRollingAmount = computed(() =>
    formatFiat(freespin.value.rollingCurrentAmount ?? '0'),
  )

  const fullRollingAmount = computed(() =>
    formatFiat(freespin.value.rollingFullAmount ?? '1'),
  )

  const currentRewardAmount = computed(() =>
    formatFiat(freespin.value.freespinCurrentRewardAmount ?? '0'),
  )

  const rollingProgress = computed(() => {
    if (
      !freespin.value.rollingCurrentAmount ||
      !freespin.value.rollingFullAmount
    )
      return ''
    return new Decimal(freespin.value.rollingCurrentAmount)
      .div(freespin.value.rollingFullAmount)
      .mul(100)
      .toFixed(2)
      .toString()
  })

  const bettingProgress = computed(() => {
    if (!freespin.value.freespinNumberLeft) return ''

    return new Decimal(freespin.value.freespinNumber)
      .minus(freespin.value.freespinNumberLeft)
      .div(freespin.value.freespinNumber)
      .mul(100)
      .toFixed(2)
      .toString()
  })

  const wager = computed(() =>
    formatMultiplier(freespin.value.rollingMultiplier),
  )

  const status = computed(() => freespin.value.status)

  const title = computed(() => {
    if (status.value === 'new') {
      return t('bonuses.freespinPanelTitle', {
        number: freespin.value.freespinNumber,
        amount: formatFiat(freespin.value.freespinBetAmount || 0),
      })
    }
    return t('bonuses.freespinForGame', { game: gameName.value })
  })

  const router = useRouter()
  function onSubmitClick() {
    switch (status.value) {
      case 'new':
        router.replace({
          query: {
            modal: 'chooseGame',
            gameIds: freespin.value.casinoGameToChooseIds,
            userFreespinProgramId: freespin.value.userFreespinProgramId,
          },
        })
        break
      case 'inProgress':
        router.push(`/casino/games/${freespin.value.freespinGameId}`)
        break
      case 'rolling':
        router.push('/casino/')
        break
      default:
        break
    }
  }

  const subtitle = computed(() => {
    if (freespin.value.status === 'new') {
      return t('bonuses.chooseGame')
    }
    return t('bonuses.forGame', { game: gameName.value })
  })

  const buttonLabel = computed(() => {
    switch (freespin.value.status) {
      case 'inProgress':
        return t('bonuses.play')
      case 'rolling':
        return t('bonuses.makeFreespinBets')
      default:
        return t('bonuses.chooseGame')
    }
  })

  const timerTitle = computed(() => {
    switch (freespin.value.status) {
      case 'inProgress':
        return t('bonuses.activeDuringDate')
      case 'rolling':
        return t('bonuses.conditionsExpireAt')
      default:
        return t('bonuses.activationExpireAt')
    }
  })

  const isProccessingLastFreespin = computed(
    () =>
      !!(
        freespin.value.status === 'inProgress' &&
        freespin.value.freespinNumberLeft === 0 &&
        freespin.value.freespinCurrentRewardAmount
      ),
  )

  const list = computed(() => [
    {
      label: t('bonuses.denominationFS'),
      value: formatFiat(freespin.value.freespinBetAmount || 0),
    },
    {
      label: t('bonuses.bettingType'),
      value: formatFiat(freespin.value.maxRewardAmount),
    },
    {
      label: t('bonuses.spinCondition'),
      value: t('bonuses.wager', {
        multiplier: freespin.value.rollingMultiplier,
      }),
      disabled: freespin.value.rollingMultiplier === '0',
    },
  ])

  const filteredList = computed(() =>
    list.value.filter((item) => !item.disabled),
  )

  return {
    title,
    status,
    wager,
    bettingProgress,
    rollingProgress,
    currentRewardAmount,
    currentRollingAmount,
    fullRollingAmount,
    gameName,
    onSubmitClick,
    subtitle,
    buttonLabel,
    timerTitle,
    isProccessingLastFreespin,
    filteredList,
    formatFiat,
  }
}
